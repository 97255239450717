/*
 * @Auth: linjituan
 * @Date: 2023-01-11 18:55:10
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-09-08 20:21:06
 */
import news from './news'
import menus from './menus'
import casesMenus from './case/menu'
import casesNews from './case/news'
export const getNewsTemp = news
export const getMenusTemp = menus
export const getCaseMenusTemp = casesMenus
export const getCaseNewsTemp = casesNews
